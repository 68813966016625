<script>
import { onMounted } from "vue";
import useUsers from "../../../hooks/useUsers";
import pageTitle, { setPageTitle } from "../../../utils/pageTitle";

export default {
  setup() {
    const { woocommerceUsers, getWoocommerceUsers } = useUsers();

    const headers = [
      { title: "Name", key: "name" },
      { title: "Email", key: "email" },
      { title: "Title", key: "title" },
      { title: "Client Name", key: "client.name" },
      { title: "WooCommerce URL", key: "woocommerce_url" },
      { title: "Tenant", key: "tenant.name" },
    ];

    onMounted(async () => {
      setPageTitle("WooCommerce Api Users");
      await getWoocommerceUsers();
    });

    return {
      woocommerceUsers,
      headers,
      pageTitle,
    };
  },
};
</script>
<template>
  <v-card class="ma-2" v-if="woocommerceUsers">
    <v-card-title>{{ pageTitle }}</v-card-title>
    <v-card-subtitle>
      Use this section to view, edit and delete WooCommerce Users.
    </v-card-subtitle>

    <v-data-table :headers="headers" :items="woocommerceUsers" class="elevation-1">
      <template v-slot:item.woocommerce_url="{ item }">
        <a :href="item.woocommerce_url" target="_blank">{{ item.woocommerce_url }}</a>
      </template>
    </v-data-table>
  </v-card>
</template>

<style scoped>
a {
  color: #1976d2;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
